import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography, Link, TextField, Button, Container, Popper, Popover, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Facebook, Instagram, Twitter, X } from '@mui/icons-material';
import useWindowDimensions from '../hooks/useWindowDimensions';
import NameQuestions from './nameQuestions';
import { useAuthenticator } from '@aws-amplify/ui-react';

interface NameGuessProps {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gameCompleted?: boolean;
    questions?: any;
}

const NameGuess: React.FC<NameGuessProps>  = (props) => {
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gameCompleted, setGameCompleted] = useState(false);
    const [gameWon, setGameWon] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 600);
    const [score, setScore] = useState(0);
    const {user} = useAuthenticator((context) => [context.user]);
    const {width} = useWindowDimensions();

    useEffect(() => {
        console.log('width:', smallScreen);
        if (props.gameCompleted) {
            setGameCompleted(true);
            setFirstName(props.firstName || '');
            setMiddleName(props.middleName || '');
            setLastName(props.lastName || '');
        }
        return () => {
            // Add your logic to clean up on component unmount here
        };
    }, []);

    useEffect(() => {
        setSmallScreen(width < 600);
    }, [width])

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleMiddleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMiddleName(event.target.value);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const increaseScore = (newPoints: number) => {
        setScore(score + newPoints);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // TODO send guess to API
        if (!firstName && props.firstName) {
            return; //TODO error handling
        }
        if (!middleName && props.middleName) {
            return;
        }
        if (!lastName && props.lastName) {
            return;
        }
        if (firstName.toLowerCase() === props.firstName?.toLowerCase() && middleName.toLowerCase() === props.middleName?.toLowerCase() && lastName.toLowerCase() === props.lastName?.toLowerCase()) {
            setGameCompleted(true);
            setGameWon(true);
            setAnchorEl(document.getElementById('congrats'));
        }
        increaseScore(10);
        console.log('Submitted name:', firstName, middleName, lastName);
        console.log("User: ", user);
        if (user) {
            makeGuess();
        } else {
            localStorage.setItem('guess', JSON.stringify({firstName, middleName, lastName}));
            localStorage.setItem('score', JSON.stringify(score));
        }
    };

    const generateWithMiddleName = () => {
        return (
        smallScreen ?
        <Box margin={2}>
            <Typography variant="h4" component="h1" align="center">
                {props.firstName?.charAt(0)} {props.middleName?.charAt(0)} {props.lastName?.charAt(0)}
            </Typography>
            <Stack spacing={2} justifyContent="center" alignItems="center">
                <TextField id="first-input" label="First Name" variant="outlined" value={firstName} onChange={handleFirstNameChange}/>
                <TextField id="middle-input" label="Middle Name" variant="outlined" value={middleName} onChange={handleMiddleNameChange}/>
                <TextField id="last-input" label="Last Name" variant="outlined" value={lastName} onChange={handleLastNameChange}/>
            </Stack>
        </Box>
        : <Grid margin={2} container spacing={3}>
            <Grid sm={4}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography variant="h4" component="h1" align="center">
                        {props.firstName?.charAt(0)}
                    </Typography>
                    <TextField id="first-input" label="First Name" variant="outlined" value={firstName} onChange={handleFirstNameChange}/>
                </Stack>
            </Grid>
            <Grid sm={4}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography variant="h4" component="h1" align="center">
                        {props.middleName?.charAt(0)}
                    </Typography>
                    <TextField id="middle-input" label="Middle Name" variant="outlined" value={middleName} onChange={handleMiddleNameChange}/>
                </Stack>
            </Grid>
            <Grid sm={4}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography variant="h4" component="h1" align="center">
                        {props.lastName?.charAt(0)}
                    </Typography>
                    <TextField id="last-input" label="Last Name" variant="outlined" value={lastName} onChange={handleLastNameChange}/>
                </Stack>
            </Grid>
        </Grid>
        );
    };

    const generateWithoutMiddleName = () => {
        return (
            <Grid container spacing={3}>
                <Grid md={6}>
                    <Stack justifyContent="center" alignItems="center">
                        <Typography variant="h4" component="h1" align="center">
                            {props.firstName?.charAt(0)}
                        </Typography>
                        <TextField id="first-input" label="First Name" variant="outlined" value={firstName} onChange={handleFirstNameChange}/>
                    </Stack>
                </Grid>
                <Grid md={6}>
                    <Stack justifyContent="center" alignItems="center">
                        <Typography variant="h4" component="h1" align="center">
                            {props.lastName?.charAt(0)}
                        </Typography>
                        <TextField id="last-input" label="Last Name" variant="outlined" value={lastName} onChange={handleLastNameChange}/>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Stack alignItems="center">
                {props.middleName ? generateWithMiddleName() : generateWithoutMiddleName()}
                {gameCompleted ? 
                        <Typography variant="h4" color="text.secondary" align="center">
                            You guessed it! Congratulations!
                        </Typography>
                    : <Button variant="contained" onClick={handleSubmit}>Guess</Button>}
                <Typography margin={1} variant="h6" color="text.secondary" align="center">
                    Score: {score}
                </Typography>
            </Stack>
            {gameWon ?
                <Popover onClose={() => setGameWon(false)} open={true} anchorReference={"none"} sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h4" color="text.secondary" align="center">
                            {props.firstName} {props.middleName} {props.lastName} 
                        </Typography>
                        <Typography variant="h6" color="text.secondary" align="center">
                            Score: 100
                        </Typography>
                        <Typography marginTop={5} variant="h4" color="text.secondary" align="center">
                            Brag to your friends!
                        </Typography>
                        <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
                            <IconButton href='https://www.facebook.com/dialog/share?app_id=1325895904848936&display=popup&href=https%3A%2F%2Fwww.famousinitials.com&redirect_uri=https%3A%2F%2Fwww.famousinitials.com'>
                                <Facebook fontSize='large' />
                            </IconButton>
                            <IconButton href="https://twitter.com/intent/tweet?screen_name=FamousInitials&ref_src=twsrc%5Etfw">
                                <X fontSize='large' />
                            </IconButton>
                        </Stack>
                    </Box>
                </Popover>
                : null}
            <NameQuestions questions={props.questions} increaseScore={increaseScore} />
        </>
  );

  function makeGuess() {
        fetch('https://api.famousinitials.com/games/', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.error('There was an error!', error);
            // Handle any errors here
        });
    }
};

export default NameGuess;