import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Authenticator, ThemeProvider, createTheme } from '@aws-amplify/ui-react';
import Home from './pages/Home';
import { CssBaseline } from '@mui/material';
import { lightModeOptions } from './configuration/lightMode';
import Footer from './components/footer'; // Import the named export 'Footer' component
import Header from './components/header';
import Privacy from './pages/Privacy';
import HowToPlay from './pages/HowToPlay';

const theme = createTheme({
    ...lightModeOptions,
  name: 'Famous Initials Theme',
});

const App: React.FC = () => (
    <Authenticator.Provider>
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/howtoplay" element={<HowToPlay />} />
        </Routes>
      </Router>
      <Footer />
    </Authenticator.Provider>
);

export default App;
