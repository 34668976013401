import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const Footer: React.FC  = () => {
    return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  > 
    <Paper sx={{position: "sticky", bottom: 0, marginTop:"auto"
    }}>
        <Typography variant="body2" align="center" gutterBottom>
            <Link color="inherit" href="/privacy">Privacy Policy</Link>
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="/">
            Famous Initials LLC
        </Link>{' '}
        {new Date().getFullYear()}.
        </Typography>
    </Paper>
    </Box>
  );
}



export default Footer;