import { ThemeOptions } from '@mui/material/styles/createTheme';

export const lightModeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
          default: '#eeeeee',
          paper: '#f5f5f5',
        },
    },
};
