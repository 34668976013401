import { Amplify } from 'aws-amplify';

function configureAmplify() {
    Amplify.configure({
    Auth: {
        Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_OUFO8KHgB',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: '7rm2333s67enr570dgq0k12l5t',
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-2:3bce3be3-10af-4278-b0ff-c2110b175040',
        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: true,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link'
        loginWith: {
            // OPTIONAL - Hosted UI configuration
            oauth: {
            domain: 'famousinitials-user-pool.auth.us-east-2.amazoncognito.com',
            scopes: [
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: ['https://famousinitials.com/','http://localhost:3000/'],
            redirectSignOut: ['https://famousinitials.com/','http://localhost:3000/'],
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
        }
        }
    }
    });

    // You can get the current config object
    return Amplify.getConfig();
}

export default configureAmplify;