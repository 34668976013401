import React, { useEffect, useState } from 'react';
import NameGuess from '../components/nameGuess';
import { Container } from '@mui/material';
import NameQuestions from '../components/nameQuestions';

const Home: React.FC = () => {
    const [gameState, setGameState] = useState<any>({});

    useEffect(() => {
        loadGame();

        return () => {
            // Code to clean up on component unmount
        };
    }, []);

    return (
        <Container>
            <NameGuess firstName={gameState.firstname} middleName={gameState.middlename} lastName={gameState.lastname} questions={gameState.questions}/>
        </Container>
    );

    function loadGame() {
        fetch(process.env.REACT_APP_API_URL + '/games/', {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setGameState(data);
        })
        .catch(error => {
            console.error('There was an error!', error);
            // Handle any errors here
        });
    }
};
export default Home;;
