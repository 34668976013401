import { Box } from '@mui/material';
import React from 'react';
import Markdown from 'markdown-to-jsx';

const Privacy: React.FC = () => {
    return (
        <Box margin={2}>
        <Markdown>
            {`
# Privacy Policy for Famous Initials

**Last Updated:** August 28, 2024

At *Famous Initials*, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of data we collect, how we use it, and your rights regarding that information.

## 1. Information We Collect

**Email Addresses**: We collect your email address when you create an account or log in to our website. This information is essential for account management, communication, and service provision.

**Non-Identifying Information**: We collect non-identifying information such as your browser type, system details, and location data. This data helps us understand how our users interact with the website, allowing us to improve our services and perform analytics.

**Additional Data**: We may collect additional data through third-party advertising platforms, Google Analytics, and social media integrations for login and sharing purposes.

## 2. How We Use Your Information

- **Account Management**: Email addresses are used for account creation, authentication, and communication regarding your account.
- **Metrics and Analytics**: Non-identifying system and location information are utilized to analyze user behavior, monitor website performance, and enhance user experience.
- **Advertising and Marketing**: Additional data collected may be used by third-party advertising platforms to deliver targeted ads and improve marketing strategies.

## 3. Third-Party Sharing

We share certain data with third-party service providers, including:
- **Google Analytics**: For tracking and analyzing website usage.
- **Social Media Platforms**: For login authentication and content sharing.
- **Advertising Partners**: For delivering personalized ads and improving our marketing efforts.

## 4. User Rights

You have the right to:
- **Access** the personal data we hold about you.
- **Correct** any inaccurate information.
- **Request Deletion** of your data at any time by contacting us at [privacy@famousinitials.com](mailto:privacy@famousinitials.com).
- **Opt-Out** of certain data collection practices by adjusting your browser settings.

## 5. Data Storage and Security

All data is securely stored on Amazon Web Services (AWS) servers, utilizing industry-standard encryption and security measures. We retain your data for as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is required or permitted by law.

## 6. International Data Transfers

If you are accessing our website from outside the country where our servers are located, your data may be transferred across international borders. We take steps to ensure that your data is handled in accordance with applicable privacy laws, including the General Data Protection Regulation (GDPR).

## 7. Policy Updates

We may update this Privacy Policy from time to time. Any changes will be posted on this page. We encourage you to review this page periodically to stay informed of any updates.

## 8. Contact Us

If you have any questions or concerns about this Privacy Policy, please contact us at [privacy@famousinitials.com](mailto:privacy@famousinitials.com).`}
        </Markdown>
        </Box>
    );
};

export default Privacy;