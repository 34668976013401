import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {
    questions: {
        standard?: Question[];
        unique?: Question[];
    },
    increaseScore: (newPoints: number) => void;
}

interface Question { 
    question: string;
    answer: string;
    asked: boolean;
    id: string;
};

const NameQuestions: React.FC<Props> = (props) => {
    const [questions, setQuestions] = useState<Question[]>([]);
    const [uniqueProvided, setUniqueProvided] = useState(false);

    const onShowAnswer = (question: Question) => {
        var updatedQuestions = questions.map(q => {
            if (q.id === question.id) {
                return {
                    ...q,
                    asked: true
                };
            }
            return q;
            
        });
        if (updatedQuestions.every(q => q.asked) && !uniqueProvided) {
            console.log('All questions asked', props.questions?.unique);
            updatedQuestions = updatedQuestions.concat(props.questions?.unique || []);
            setUniqueProvided(true);
        }
        console.log(updatedQuestions);
        setQuestions(updatedQuestions);
        props.increaseScore(10);
        // TODO send question to API
    };


    useEffect(() => {
        setQuestions(props.questions?.standard || []);
    }, [props.questions]);

    return (
        <Box margin={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore  />}>
                    <Typography variant="h6">Questions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {questions.map((question) => (
                            <ListItem key={question.id}>
                                <ListItemText primary={question.question} />
                                {question.asked ? <Typography>{question.answer}</Typography> : <Button variant="contained" color="primary" onClick={() => onShowAnswer(question)}>Show Answer</Button>}
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default NameQuestions;